import { useCallback, useEffect, useState } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { loadEnv } from '../configEnv'

interface Document {
  document_id: string
  filename: string
  uri: string
  format: string
  type: string
  created_at: string
}

export interface ShipmentInfo {
  shipmentId: string
  logisticsProvider: string
  coreApiOrderId: string
  clientOrderId: string
  state: string
  errors: any[]
  insertedAt: string
  updatedAt: string
  trackingId: string
  trackingUrl: string
  documents: Document[]
}

async function fetchShipment(uuid: string): Promise<ShipmentInfo> {
  if (!uuid) {
    return {} as ShipmentInfo
  }

  const env = await loadEnv()
  const shipment = await ApiClient.get({
    path: env.QU_PLATFORM_API_URL + `/shipments`,
    params: {
      client_order_id: uuid,
    },
  })

  return shipment
}

export function useShipmentInfo(uuid: string): { shipmentInfo: ShipmentInfo | null } {
  const [shipmentInfo, setShipmentInfo] = useState<ShipmentInfo | null>(null)

  const setShipment = useCallback(async () => {
    try {
      const response = await fetchShipment(uuid)

      setShipmentInfo(response)
    } catch (error) {
      console.error(error)
    }
  }, [uuid])

  useEffect(() => {
    setShipment()
  }, [uuid, setShipment])

  return {
    shipmentInfo,
  }
}
