import React, { useState, useEffect, useMemo } from 'react'
import {
  ApiOutlined,
  ClockCircleOutlined,
  EditOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  LinkOutlined,
  ShoppingOutlined,
} from '@ant-design/icons'
import { Row, Col, Card as AntCard, Typography, Spin, Result, Modal, Button, Tooltip, Badge, notification } from 'antd'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { space } from '@quiqupltd/ui-kit.sizes'
import { RouteComponentProps } from 'react-router-dom'
import { useOrderDetail } from '../../hooks/order-detail'
import { useOrderHistory } from '../../hooks/order-history'
import { usePaymentChanges } from '../../hooks/payment-changes'
import { useOrderTransfers } from '../../hooks/order-transfers'
import { useAddressHistory } from '../../hooks/address-history'
import { useCommentHistory } from '../../hooks/comment-history'
import { useOrderSeparate } from '../../hooks/order-separate'
import OrderHistory from './OrderHistory'
import AddressHistory from './AddressHistory'
import CommentHistory from './CommentHistory'
import PaymentChanges from './PaymentChanges'
import OrderTransfers from './OrderTransfers'

import {
  OrderInterface,
  OrderKindLabels,
  OrderStatesLabels,
  OrderPaymentTypes,
  UpdateLocationAddressFormValues,
  UpdateContactPhoneFormValues,
  UpdatePaymentamountFormValues,
  OrderStates,
} from '../../types/order.type'
import { hasLiveMission, getStateDot } from '../../utils/orders'
import TrackingURL from '../TrackingUrl'
import MissionId from '../MissionId'
import Routes from '../../types/routes.type'
import downloadLabels from '../../utils/downloadLabels'
import { DATE_TIME_FORMAT } from '../../utils/time'
import UpdateLocationModal from './UpdateLocationModal'
import UpdatePhoneModal from './UpdatePhoneModal'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { loadEnv } from '../../configEnv'
import { CardTabListType } from 'antd/lib/card'
import UpdatePaymentAmountModal from './UpdatePaymentAmountModal'
import UpdateNotesModal from './UpdateNotesModal'
import { useShipmentInfo } from '../../hooks/shipment-info'

const { Title } = Typography

interface Params {
  id: string
}

interface ContainerProps {
  loading?: boolean
}

interface DetailsCardProps {
  height?: number | 'auto'
}

interface DetailProps {
  width?: number
}

interface ApiError {
  errors?: string[]
  error?: string
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 50px;
  padding-top: ${space(4)}px;
  max-width: 1400px;
  margin: 0 auto;

  ${(p) =>
    p.loading &&
    `
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`
Container.defaultProps = { loading: false }

const DetailsColumn = styled(Col)``

export const DetailsCard = styled(AntCard)<DetailsCardProps>`
  min-height: ${(p) => (p.height && p.height !== 'auto' ? `${p.height}px` : 'auto')};
  height: 100%;
`
DetailsCard.defaultProps = { height: 'auto' }

const DetailsCardTitle = styled.span`
  color: ${(p) => p.theme.midnight500};
`

const Detail = styled.div<DetailProps>`
  margin-bottom: ${space(2)}px;
  width: ${(p) => (p.width ? `${p.width}px` : 'auto')};
`

const DetailTitle = styled.h3`
  font-size: 0.8rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.midnight500};
`

const Barcodes = styled.ol`
  padding-left: 15px;
`

const DeliveryRequirement = styled.div`
  display: flex;

  align-items: end;

  & > .icon {
    margin-left: auto;
  }
`

const ExternalLink = styled.a`
  color: inherit;
  margin-left: ${space(0.5)}px;
  text-decoration: underline;
  margin: auto;

  &:hover {
    text-decoration: underline;
  }
`

const LinkText = styled.span`
  margin-left: ${space(0.5)}px;
`

const HeaderRow = styled(Row)`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
`

const ActionCol = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;

  > :nth-last-child(n + 2) {
    margin-right: ${space(1)}px !important;
  }
`

const DocumentImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

const DocumentImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ErrorValue = styled.div`
  color: red;
`

export function MissionLink(props: { link: string; icon: JSX.Element; text: string }): JSX.Element {
  return (
    <ExternalLink href={props.link}>
      {props.icon}
      <LinkText>{props.text}</LinkText>
    </ExternalLink>
  )
}
function DetailItem(props: {
  title: string
  children: React.ReactNode
  width?: number
  rightAccessory?: React.ReactNode
}): JSX.Element {
  return (
    <Detail width={props.width}>
      <DetailTitle>
        {props.title}
        {props.rightAccessory}
      </DetailTitle>
      {props.children}
    </Detail>
  )
}

export function getPaymentDetail(order: OrderInterface): string {
  if (order.paymentMode === 'paid_on_delivery') {
    return `${OrderPaymentTypes[order.paymentMode]} - AED ${order.paymentAmount}`
  }

  return order.paymentMode || 'Pre-paid'
}

function OrderDetail(props: RouteComponentProps<Params>): JSX.Element | null {
  const { order, refreshOrder } = useOrderDetail(props.match.params.id)
  const { shipmentInfo } = useShipmentInfo(order.result?.uuid ?? '')
  const orderHistory = useOrderHistory(props.match.params.id)
  const addressHistory = useAddressHistory(props.match.params.id)
  const { result, loading, error } = order
  const commentHistory = useCommentHistory(result?.uuid)
  const paymentChanges = usePaymentChanges(props.match.params.id)
  const orderTransfers = useOrderTransfers(props.match.params.id)
  const dispatchRootUrl = getMissionRootUrl(result?.regionName)

  const { separateState, separateActions } = useOrderSeparate(
    result?.id,
    result?.uuid,
    result?.currentInternalOrderId,
    result?.mission?.id
  )
  const [isUpdateLocationModalOpen, setIsUpdateLocationModalOpen] = useState(false)
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false)
  const [updateLocationErrorMessage, setUpdateLocationErrorMessage] = useState<string | undefined>()
  const [isUpdatePhoneModalOpen, setIsUpdatePhoneModalOpen] = useState(false)
  const [isUpdatePaymentamountModalOpen, setIsUpdatePaymentamountModalOpen] = useState(false)
  const [isUpdatingPhone, setIsUpdatingPhone] = useState(false)
  const [isUpdatingPaymentAmount, setIsUpdatingPaymentAmount] = useState(false)
  const [updatePhoneErrorMessage, setUpdatePhoneErrorMessage] = useState<string | undefined>()
  const [updatePaymentAmountErrorMessage, setUpdatePaymentAmountErrorMessage] = useState<string | undefined>()
  const [waypointType, setWaypointType] = useState<string | undefined>()
  const [activeTabKey, setActiveTabKey] = useState<string>('orderHistory')
  const [printLabel, setPrintLabel] = useState(false)
  const [printLabelConfirmation, setPrintLabelConfirmation] = useState(false)
  const [printLabelConfirmationModal, setPrintLabelConfirmationModal] = useState(false)
  const [updateNotesOpen, setUpdateNotesOpen] = useState<string | undefined>(undefined)
  const [isUpdatingNotes, setIsUpdatingNotes] = useState(false)
  const [updateNotesErrorMessage, setUpdateNotesErrorMessage] = useState<string | undefined>(undefined)

  const { proofOfDeliveryDocs, hasProofOfDelivery } = useMemo(() => {
    const proofOfDeliveryDocs =
      shipmentInfo?.documents?.filter((document) => document.type === 'proof_of_delivery') ?? []

    return {
      proofOfDeliveryDocs,
      hasProofOfDelivery: proofOfDeliveryDocs.length > 0,
    }
  }, [shipmentInfo])

  const { proofOfPickupDocs, hasProofOfPickup } = useMemo(() => {
    const proofOfPickupDocs = shipmentInfo?.documents?.filter((document) => document.type === 'proof_of_pickup') ?? []

    return {
      proofOfPickupDocs,
      hasProofOfPickup: proofOfPickupDocs.length > 0,
    }
  }, [shipmentInfo])

  const { hasErrors, errors } = useMemo(() => {
    const errors = shipmentInfo?.errors ?? []

    return {
      hasErrors: errors.length > 0,
      errors,
    }
  }, [shipmentInfo])

  function getMissionRootUrl(regionName: string | undefined) {
    return regionName === 'Dubai' ? window.env.dubaiDispatchUrl : window.env.abudhabiDispatchUrl
  }

  useEffect(() => {
    if (order.result) {
      setPrintLabel(order.result.printLabel)
    }
  }, [order])

  useEffect(() => {
    if (separateState.successMessage) {
      props.history.push({
        pathname: Routes.ROOT,
        state: {
          message: separateState.successMessage,
        },
      })
    }
  }, [separateState.successMessage, props.history])

  useEffect(() => {
    if (separateState.error) {
      notification.warn({
        message: 'Error',
        description: (
          <>
            {separateState.error}
            <a href={`${dispatchRootUrl}${result?.mission?.id}`}>try from dispatch</a>
          </>
        ),
        duration: 0,
      })
    }
  }, [dispatchRootUrl, result, separateState.error])

  useEffect(() => {
    if (separateState.successMessage) {
      props.history.push({
        pathname: Routes.ROOT,
        state: {
          message: separateState.successMessage,
        },
      })
    }
  }, [separateState.successMessage, props.history])

  useEffect(() => {
    if (separateState.error) {
      notification.warn({
        message: 'Error',
        description: (
          <>
            {separateState.error}
            <a href={`${dispatchRootUrl}${result?.mission?.id}`}>try from dispatch</a>
          </>
        ),
        duration: 0,
      })
    }
  }, [dispatchRootUrl, result, separateState.error])

  useEffect(() => {
    if (separateState.successMessage) {
      props.history.push({
        pathname: Routes.ROOT,
        state: {
          message: separateState.successMessage,
        },
      })
    }
  }, [separateState.successMessage, props.history])

  useEffect(() => {
    if (separateState.error) {
      notification.warn({
        message: 'Error',
        description: (
          <>
            {separateState.error}
            <a href={`${dispatchRootUrl}${result?.mission?.id}`}>try from dispatch</a>
          </>
        ),
        duration: 0,
      })
    }
  }, [dispatchRootUrl, result, separateState.error])

  function handleUpdateLocationButtonClick(type: string): void {
    setWaypointType(type)
    setIsUpdateLocationModalOpen(true)
  }

  function handleUpdateLocationModalClose(): void {
    setUpdateLocationErrorMessage(undefined)
    setIsUpdateLocationModalOpen(false)
  }

  async function handleUpdateLocationSubmit(values: UpdateLocationAddressFormValues): Promise<void> {
    try {
      setIsUpdatingLocation(true)
      setUpdateLocationErrorMessage(undefined)
      const [lat, lng] = values.coordinates.split(', ')
      const env = await loadEnv()
      await ApiClient.put({
        path: env.EX_CORE_API_URL + `/orders/${result?.id}/waypoints`,
        data: { ...values, coordinates: { lat, lng } },
      })
      handleUpdateLocationModalClose()
      refreshOrder()
    } catch (error) {
      const apiError = error as ApiError
      setUpdateLocationErrorMessage(`Failed to update the order location ${JSON.stringify(apiError?.errors)}`)
    } finally {
      setIsUpdatingPhone(false)
    }
  }

  function handleContactPhoneButtonClick(type: string): void {
    setIsUpdatePhoneModalOpen(true)
    setWaypointType(type)
  }

  function handleUpdatePhoneModalClose(): void {
    setUpdatePhoneErrorMessage(undefined)
    setIsUpdatePhoneModalOpen(false)
  }

  async function handleUpdatePaymentAmountSubmit(values: UpdatePaymentamountFormValues): Promise<void> {
    try {
      if (values.paymentMode === 'pre_paid' && Number(values.paymentAmount) > 0) {
        setUpdatePaymentAmountErrorMessage('Payment amount should be 0')
        return
      }
      if (values.paymentMode === 'paid_on_delivery' && Number(values.paymentAmount) <= 0) {
        setUpdatePaymentAmountErrorMessage('Payment amount should be greater than 0')
        return
      }
      setIsUpdatingPaymentAmount(true)
      setUpdatePaymentAmountErrorMessage(undefined)
      const env = await loadEnv()
      await ApiClient.put({
        path: env.EX_CORE_API_URL + `/orders/${result?.id}`,
        data: { ...values },
      })
      handleUpdatePaymentAmountModalClose()
      refreshOrder()
    } catch (error) {
      const apiError = error as ApiError
      setUpdatePaymentAmountErrorMessage(`Failed to update the payment amount ${JSON.stringify(apiError?.errors)}`)
    } finally {
      setIsUpdatingPaymentAmount(false)
    }
  }

  function handleUpdatePaymentAmountButtonClick(): void {
    setIsUpdatePaymentamountModalOpen(true)
  }

  function handleUpdatePaymentAmountModalClose(): void {
    setUpdatePaymentAmountErrorMessage(undefined)
    setIsUpdatePaymentamountModalOpen(false)
  }

  async function handleUpdatePhoneSubmit(values: UpdateContactPhoneFormValues, waypointType: string): Promise<void> {
    try {
      setIsUpdatingPhone(true)
      setUpdatePhoneErrorMessage(undefined)
      const env = await loadEnv()
      await ApiClient.put({
        path: env.EX_CORE_API_URL + `/orders/${result?.id}/update_contact_phone`,
        data: { ...values, waypointType },
      })
      handleUpdatePhoneModalClose()
      refreshOrder()
    } catch (error) {
      const apiError = error as ApiError
      setUpdatePhoneErrorMessage(`Failed to update the phone number ${JSON.stringify(apiError?.errors)}`)
    } finally {
      setIsUpdatingPhone(false)
    }
  }

  function handleActionClick() {
    props.history.push({
      pathname: Routes.BULK_ACTIONS,
      state: {
        selectedOrders: [result],
      },
    })
  }

  function handleDownloadLabelClick() {
    if (result) {
      downloadLabels([result])
    }
  }

  function handlePrintLabelClick() {
    if (result) {
      downloadLabels([result])
      setPrintLabelConfirmation(true)
    }
  }

  function handlePrintLabelConfirmationClick() {
    setPrintLabelConfirmationModal(true)
  }

  async function handlePrintLabelConfirmationModalClick(bool: boolean) {
    if (bool) {
      try {
        const env = await loadEnv()
        await ApiClient.put({
          path: env.EX_CORE_API_URL + `/orders/${result?.id}/update_print_label`,
          data: { print_label: false },
        })
      } finally {
        setPrintLabelConfirmation(false)
        setPrintLabelConfirmationModal(false)
        setPrintLabel(false)
      }
    } else {
      setPrintLabelConfirmationModal(false)
    }
  }

  function handleUpdateNotesClick(waypointType: string) {
    setUpdateNotesOpen(waypointType)
  }
  async function handleUpdateNotesSubmit(notes: string, waypointType: string) {
    try {
      setIsUpdatingNotes(true)
      setUpdateNotesErrorMessage(undefined)
      const env = await loadEnv()
      await ApiClient.put({
        path: env.EX_CORE_API_URL + `/orders/${result?.id}/notes`,
        data: { notes, waypointType },
      })
      setIsUpdatingNotes(false)
      setUpdateNotesOpen(undefined)
      refreshOrder()
    } catch (error) {
      const apiError = error as ApiError
      setUpdateNotesErrorMessage(`Failed to update the notes ${JSON.stringify(apiError?.errors ?? apiError?.error)}`)
      setIsUpdatingNotes(false)
      console.log(error)
    }
  }

  const tabList: CardTabListType[] | { key: string; tab: string }[] | undefined = []

  if (orderHistory.result?.length > 0) {
    tabList.push({
      key: 'orderHistory',
      tab: 'Order History',
    })
  }

  if (addressHistory.result?.length > 0) {
    tabList.push({
      key: 'addressHistory',
      tab: 'Address History',
    })
  }

  if (commentHistory.result?.length > 0) {
    tabList.push({
      key: 'commentHistory',
      tab: 'Comment History',
    })
  }

  if (paymentChanges.result?.length > 0) {
    tabList.push({
      key: 'paymentChanges',
      tab: 'Payment Changes',
    })
  }

  if (orderTransfers.result?.length > 0) {
    tabList.push({
      key: 'orderTransfers',
      tab: 'Order Transfers',
    })
  }

  const contentList: Record<string, React.ReactNode> = {
    orderHistory: orderHistory.result?.length > 0 ? <OrderHistory history={orderHistory.result} /> : null,
    addressHistory: addressHistory.result?.length > 0 ? <AddressHistory history={addressHistory.result} /> : null,
    commentHistory: commentHistory.result?.length > 0 ? <CommentHistory history={commentHistory.result} /> : null,
    paymentChanges: paymentChanges.result?.length > 0 ? <PaymentChanges history={paymentChanges.result} /> : null,
    orderTransfers: orderTransfers.result?.length > 0 ? <OrderTransfers history={orderTransfers.result} /> : null,
  }

  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  function handleSeparateOrder() {
    separateActions.separateOrder()
  }

  if (loading) {
    return (
      <Container loading data-testid="loading-spinner">
        <Spin />
      </Container>
    )
  }

  if (error || !result) {
    return (
      <Container>
        <Result
          status="404"
          title="Order not found"
          subTitle="Sorry, there was an error."
          extra={
            <Button type="primary" href={`/${props.location.search}`}>
              Try again
            </Button>
          }
        />
      </Container>
    )
  }

  if (!result.origin) {
    return (
      <Container>
        <Result
          status="warning"
          title="Invalid order"
          subTitle="This order does exist but is invalid"
          extra={
            <Button type="primary" href="/">
              Try again
            </Button>
          }
        />
      </Container>
    )
  }
  const isLive = hasLiveMission((result.mission && result.mission.state) || '')
  const stateDot = getStateDot(isLive, OrderStatesLabels[result.state])

  const renderAddressDetails = (
    address: OrderInterface['destination']['address'] | OrderInterface['origin']['address']
  ) => (
    <>
      <p>
        <strong>Address 1: </strong>
        {address.address1 ?? 'Not provided'}
      </p>
      <p>
        <strong>Address 2: </strong>
        {address.address2 ?? 'Not provided'}
      </p>
      <p>
        <strong>Apartment Number: </strong>
        {address.apartmentNumber ?? 'Not provided'}
      </p>
      <p>
        <strong>Town: </strong>
        {address.town ?? 'Not provided'}
      </p>
      <p>
        <strong>Country: </strong>
        {address.country ?? 'Not provided'}
      </p>
      <p>
        <strong>Coords: </strong>
        {address.coordinates.lat}, {address.coordinates.lng}
      </p>
    </>
  )

  return (
    <Container>
      <UpdateLocationModal
        isOpen={isUpdateLocationModalOpen}
        onClose={handleUpdateLocationModalClose}
        onSubmit={handleUpdateLocationSubmit}
        address={waypointType === 'origin' ? result.origin.address : result.destination.address}
        isLoading={isUpdatingLocation}
        errorMessage={updateLocationErrorMessage}
        pinCheckType="oms_update"
        waypointType={waypointType}
      />
      <UpdatePhoneModal
        isOpen={isUpdatePhoneModalOpen}
        onClose={handleUpdatePhoneModalClose}
        onSubmit={handleUpdatePhoneSubmit}
        contact={waypointType === 'origin' ? result.origin : result.destination}
        isLoading={isUpdatingPhone}
        errorMessage={updatePhoneErrorMessage}
        waypointType={waypointType === 'origin' ? 'origin' : 'destination'}
      />
      <UpdatePaymentAmountModal
        isOpen={isUpdatePaymentamountModalOpen}
        onClose={handleUpdatePaymentAmountModalClose}
        onSubmit={handleUpdatePaymentAmountSubmit}
        paymentMode={result.paymentMode}
        paymentAmount={result.paymentAmount}
        isLoading={isUpdatingPaymentAmount}
        errorMessage={updatePaymentAmountErrorMessage}
      />
      <UpdateNotesModal
        isOpen={updateNotesOpen !== undefined}
        onClose={() => {
          setUpdateNotesOpen(undefined)
          setUpdateNotesErrorMessage(undefined)
        }}
        onSubmit={handleUpdateNotesSubmit}
        notes={(updateNotesOpen === 'origin' ? result.origin.notes : result.destination.notes) ?? ''}
        isLoading={isUpdatingNotes}
        errorMessage={updateNotesErrorMessage}
        waypointType={updateNotesOpen ?? ''}
      />
      <Modal
        visible={printLabelConfirmationModal}
        footer={null}
        onCancel={() => handlePrintLabelConfirmationModalClick(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Col span={16} style={{ marginBottom: '20px', textAlign: 'center' }}>
            Do you confirm that you printed the label?
          </Col>
          <Col>
            <Button
              type="primary"
              disabled={loading}
              onClick={() => handlePrintLabelConfirmationModalClick(true)}
              data-testid="download-label-button"
              style={{ backgroundColor: '#12c812', color: 'white', marginRight: '20px' }}
            >
              Yes
            </Button>
            <Button
              type="primary"
              danger={true}
              disabled={loading}
              onClick={() => handlePrintLabelConfirmationModalClick(false)}
              data-testid="download-label-button"
            >
              No
            </Button>
          </Col>
        </div>
      </Modal>

      <HeaderRow justify="space-between" align="bottom">
        <Col span={16}>
          <Title level={1} data-testid="page-title">
            Order - {result.id} ({OrderStatesLabels[result.state]})
          </Title>
        </Col>
        <ActionCol span={8}>
          {printLabel && printLabelConfirmation && (
            <Button
              type="dashed"
              disabled={loading}
              onClick={handlePrintLabelConfirmationClick}
              data-testid="download-label-button"
              style={{ backgroundColor: '#12c812', color: 'white' }}
            >
              Confirm printing labels
            </Button>
          )}
          {printLabel && (
            <Button
              type="primary"
              danger={true}
              disabled={loading}
              onClick={handlePrintLabelClick}
              data-testid="download-label-button"
            >
              Print label
            </Button>
          )}

          {!printLabel && (
            <Button
              type="primary"
              disabled={loading}
              onClick={handleDownloadLabelClick}
              data-testid="download-label-button"
            >
              Download label
            </Button>
          )}

          <Button type="primary" disabled={loading} onClick={handleActionClick} data-testid="actions-button">
            Take action
          </Button>
        </ActionCol>
      </HeaderRow>
      <Row gutter={[8, 8]}>
        <DetailsColumn flex="1 1 300px">
          <DetailsCard
            title={
              <>
                <EnvironmentOutlined /> <DetailsCardTitle>Sender info</DetailsCardTitle>
              </>
            }
            data-testid="sender-info"
          >
            <DetailItem title="Name">{result.origin.contactName}</DetailItem>
            <DetailItem
              title="Phone number"
              rightAccessory={
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleContactPhoneButtonClick('origin')}
                  data-testid="update-contact-phone-btn-modal"
                />
              }
            >
              {result.origin.contactPhone}
            </DetailItem>
            <DetailItem
              title="Collection instructions"
              rightAccessory={
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleUpdateNotesClick('origin')}
                  data-testid="update-contact-phone-btn-modal"
                />
              }
            >
              {result.origin.notes?.length ? result.origin.notes : 'Not provided'}
            </DetailItem>
            <DetailItem
              title="Address"
              rightAccessory={
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleUpdateLocationButtonClick('origin')}
                  data-testid="update-origin-address-btn-modal"
                />
              }
            >
              {renderAddressDetails(result.origin.address)}
            </DetailItem>
          </DetailsCard>
        </DetailsColumn>
        <DetailsColumn flex="1 1 300px">
          <DetailsCard
            title={
              <>
                <EnvironmentFilled /> <DetailsCardTitle>Receiver info</DetailsCardTitle>
              </>
            }
            data-testid="receiver-info"
          >
            <DetailItem title="Name">{result.destination.contactName}</DetailItem>
            <DetailItem
              title="Phone number"
              rightAccessory={
                result.serviceKind !== 'partner_return' ? (
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => handleContactPhoneButtonClick('destination')}
                    data-testid="update-contact-phone-btn-modal"
                  />
                ) : undefined
              }
            >
              {result.destination.contactPhone}
            </DetailItem>
            <DetailItem
              title="Delivery instructions"
              rightAccessory={
                <Button type="link" icon={<EditOutlined />} onClick={() => handleUpdateNotesClick('destination')} />
              }
            >
              {result.destination.notes?.length ? result.destination.notes : 'Not provided'}
            </DetailItem>
            <DetailItem
              title="Address"
              rightAccessory={
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleUpdateLocationButtonClick('destination')}
                  data-testid="update-destination-address-btn-modal"
                />
              }
            >
              {renderAddressDetails(result.destination.address)}
            </DetailItem>
          </DetailsCard>
        </DetailsColumn>
        <DetailsColumn flex="1 1 300px">
          <DetailsCard
            title={
              <>
                <ShoppingOutlined /> <DetailsCardTitle>Parcel info</DetailsCardTitle>
              </>
            }
            data-testid="parcel-info"
          >
            <DetailItem title="Source">{result.source ? result.source.toUpperCase() : 'Not provided'}</DetailItem>
            <DetailItem title="Delivery before">
              {result.deliveryTime.deliveryBefore
                ? format(parseISO(result.deliveryTime.deliveryBefore), DATE_TIME_FORMAT)
                : ''}
            </DetailItem>
            <DetailItem title="Scheduled For">
              {result.scheduledFor ? format(parseISO(result.scheduledFor), DATE_TIME_FORMAT) : ''}
            </DetailItem>
            <DetailItem title="Order reference">{result.id}</DetailItem>
            <DetailItem title="Order long ID">{result.uuid}</DetailItem>
            <DetailItem title="Confidence">{result.confidence * 100}%</DetailItem>
            <DetailItem title="Partner order ID">{result.partnerOrderId || 'N/A'}</DetailItem>
            <DetailItem title="State">
              {OrderStatesLabels[result.state]}{' '}
              <Tooltip placement="top" title={stateDot.description}>
                <Badge color={stateDot.color} />
              </Tooltip>
            </DetailItem>
            <DetailItem title="Current region">{result.regionName}</DetailItem>
            {result.serviceKind && (
              <DetailItem title="Delivery service">{OrderKindLabels[result.serviceKind]}</DetailItem>
            )}

            <DetailItem
              title="Payment type"
              rightAccessory={
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={handleUpdatePaymentAmountButtonClick}
                  data-testid="update-payment-amount-btn-modal"
                />
              }
            >
              {' '}
              {getPaymentDetail(result)}
            </DetailItem>
            <DetailItem title="Shipment number">
              <Barcodes>
                {result.items.map((item) => (
                  <li key={item.parcelBarcode}>
                    {item.name} - {item.parcelBarcode}
                  </li>
                ))}
              </Barcodes>
            </DetailItem>
            <DetailItem title="Delivery attempts">{result.deliveryAttempts}</DetailItem>
            <DetailItem title="Delivery requirements" width={175}>
              <DeliveryRequirement>
                <span>Card payment enabled: </span>
                <span className="icon">
                  {result.allowedPaymentTypes && result.allowedPaymentTypes.includes('card') ? '✅' : '❌'}
                </span>
              </DeliveryRequirement>
              <DeliveryRequirement>
                <span>Cash payment enabled: </span>
                <span className="icon">
                  {result.allowedPaymentTypes && result.allowedPaymentTypes.includes('cash') ? '✅' : '❌'}
                </span>
              </DeliveryRequirement>
              <DeliveryRequirement>
                <span>Proof of ID: </span>
                <span className="icon">
                  {result?.requiredDocuments?.includes('customer_identification_photo') ? '✅' : '❌'}
                </span>
              </DeliveryRequirement>
              <DeliveryRequirement>
                <span>Requires in person delivery: </span>
                <span className="icon">{result?.requiredDocuments?.includes('in_person_delivery') ? '✅' : '❌'}</span>
              </DeliveryRequirement>
            </DetailItem>

            {result.trackingUrl && (
              <DetailItem title="Tracking URL">
                <TrackingURL url={result.trackingUrl} />
              </DetailItem>
            )}

            {result.weightKg && <DetailItem title="Parcel Weight">{result.weightKg} Kg</DetailItem>}
            <DetailItem title="Client Name">{result.user?.fullname} </DetailItem>
            <DetailItem title="Client Email">{result.user?.email} </DetailItem>
          </DetailsCard>
        </DetailsColumn>
        {isLive && (
          <DetailsColumn flex="1 1 300px">
            <DetailsCard
              title={
                <>
                  <ApiOutlined /> <DetailsCardTitle>Mission Info</DetailsCardTitle>{' '}
                </>
              }
              data-testid="mission-info"
            >
              <DetailItem title="Mission ID">
                <MissionId
                  id={result.mission?.id?.toString() || 'N/A'}
                  cancelable={[`${OrderStates.at_depot}`, `${OrderStates.received_at_depot}`].includes(result.state)}
                  loading={separateState.loading}
                  separateOrder={handleSeparateOrder}
                />
              </DetailItem>
              <DetailItem title="Courier name">{result.mission?.courier?.firstname ?? 'N/A'}</DetailItem>
              <DetailItem title="Courier email"> {result.mission?.courier?.email ?? 'N/A'}</DetailItem>
              <DetailItem title="State">{result.mission?.state ?? 'N/A'}</DetailItem>
              {result.mission && (
                <MissionLink
                  link={`${dispatchRootUrl}${result.mission?.id}`}
                  icon={<LinkOutlined />}
                  text="Check mission on dispatch"
                ></MissionLink>
              )}
            </DetailsCard>
          </DetailsColumn>
        )}
      </Row>
      {shipmentInfo?.shipmentId && (
        <Row style={{ marginTop: 8 }}>
          <DetailsColumn flex="1">
            <DetailsCard
              title={
                <>
                  <ShoppingOutlined /> <DetailsCardTitle>Shipment info</DetailsCardTitle>
                </>
              }
              data-testid="shipment-info"
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <DetailItem title="Dispatcher System">{shipmentInfo?.logisticsProvider || 'N/A'}</DetailItem>

                  <DetailItem title="Shipment ID">{shipmentInfo?.shipmentId || 'N/A'}</DetailItem>

                  <DetailItem title="Shipment Status">{shipmentInfo?.state || 'N/A'}</DetailItem>

                  <DetailItem title="Tracking URL">
                    {shipmentInfo?.trackingUrl ? <TrackingURL url={shipmentInfo?.trackingUrl} /> : 'N/A'}
                  </DetailItem>

                  <DetailItem title="Carrier">{order?.result?.carrier || 'N/A'}</DetailItem>

                  {hasErrors && (
                    <DetailItem title="Errors">
                      {errors?.map((error) => (
                        <ErrorValue key={error}>{error}</ErrorValue>
                      ))}
                    </DetailItem>
                  )}
                </Col>

                {hasProofOfDelivery && (
                  <Col xs={24} lg={6}>
                    <DetailItem title="Proof of delivery">
                      <DocumentImageContainer>
                        {proofOfDeliveryDocs.map((document) => (
                          <DocumentImage key={document.document_id} src={document.uri} alt={document.filename} />
                        ))}
                      </DocumentImageContainer>
                    </DetailItem>
                  </Col>
                )}

                {hasProofOfPickup && (
                  <Col xs={24} lg={6}>
                    <DetailItem title="Proof of pickup">
                      <DocumentImageContainer>
                        {proofOfPickupDocs.map((document) => (
                          <DocumentImage key={document.document_id} src={document.uri} alt={document.filename} />
                        ))}
                      </DocumentImageContainer>
                    </DetailItem>
                  </Col>
                )}
              </Row>
            </DetailsCard>
          </DetailsColumn>
        </Row>
      )}
      {(orderHistory.result.length > 0 || addressHistory.result.length > 0 || commentHistory.result.length > 0) && (
        <Row style={{ marginTop: 8 }}>
          <Col span={24}>
            <DetailsCard
              height={314}
              title={
                <>
                  <ClockCircleOutlined /> <DetailsCardTitle>History</DetailsCardTitle>
                </>
              }
              data-testid="history"
              tabList={tabList}
              activeTabKey={activeTabKey}
              onTabChange={(key) => {
                onTabChange(key)
              }}
            >
              {contentList[activeTabKey]}
            </DetailsCard>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default OrderDetail
